export type PreviewableLanguage =
  | "html"
  | "javascript"
  | "jsx"
  | "react"
  | "typescript";
export type CodeLanguage = PreviewableLanguage | string;

export interface StreamingContentProps {
  isOpen: boolean;
  onClose: () => void;
  streamingContent: string | null;
  language: CodeLanguage;
  title?: string;
}

export interface StreamEvent {
  data: string;
}

export const PREVIEWABLE_LANGUAGES = [
  "html",
  "javascript",
  "jsx",
  "react",
  "typescript",
];

// HTML用のベーステンプレート
export const BASE_HTML_TEMPLATE = `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script>
      // セキュリティ対策
      window.onmessage = function(e) {
        if (e.source !== parent) return;
      };
      
      // エラーハンドリング
      window.onerror = function(msg, url, line, col, error) {
        document.body.innerHTML = \`
          <div class="preview-container">
            <div class="p-4 bg-red-50 text-red-600 rounded">
              Error: \${msg}<br>
              Line: \${line}, Column: \${col}
            </div>
          </div>
        \`;
        return true;
      };
    </script>
  </head>
  <body>
    <div class="preview-container">
      <div id="root"></div>
      <div class="mt-2 text-xs text-gray-500">
        ※ プレビューはデモ表示のみです。
      </div>
    </div>
  </body>
</html>
`;

// React用のテンプレート
export const REACT_PREVIEW_TEMPLATE = `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="https://unpkg.com/react@18/umd/react.development.js"></script>
    <script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js"></script>
    <script src="https://unpkg.com/babel-standalone@6/babel.min.js"></script>
  </head>
  <body>
    <div class="preview-container p-4">
      <div id="root"></div>
      <div class="mt-2 text-xs text-gray-500">
        ※ インタラクティブな機能は制限されています。
      </div>
    </div>
  </body>
</html>
`;
