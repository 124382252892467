import React, { useEffect, useState } from "react";

type SlideModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
};

export const SlideModal: React.FC<SlideModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsAnimating(true);
      }, 50);
      return () => clearTimeout(timer);
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-[1000] flex justify-end pointer-events-none">
      <div
        className={`absolute inset-0 bg-black pointer-events-auto transition-opacity duration-500 ${
          isAnimating ? "opacity-50" : "opacity-0"
        }`}
        onClick={onClose}
      />

      <div
        className={`relative w-[32rem] h-full pointer-events-auto bg-primary-50 shadow-lg transform transition-transform duration-500 ease-out ${
          isAnimating ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ position: "fixed", right: 0 }}
      >
        <div className="absolute top-0 left-0 right-0 flex items-center justify-between p-4 border-b border-gray-200 bg-white/50 z-10">
          <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors p-2 rounded-full hover:bg-gray-100"
          >
            ✕
          </button>
        </div>

        <div className="absolute inset-x-0 top-[65px] bottom-0 overflow-hidden">
          <div className="h-full overflow-y-auto overscroll-contain">
            <div className="p-4">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
