import React, { useState, useRef, useCallback, useEffect } from "react";

import { IconButton } from "@spesill/components/molecules";

import { SlideModal } from "./SimpleMordal";
import {
  REACT_PREVIEW_TEMPLATE,
  BASE_HTML_TEMPLATE,
  PREVIEWABLE_LANGUAGES,
} from "./types";

const LANGUAGE_EXTENSIONS: Record<string, string> = {
  javascript: "js",
  typescript: "ts",
  html: "html",
  css: "css",
  jsx: "jsx",
  react: "jsx",
  python: "py",
  java: "java",
  cpp: "cpp",
  c: "c",
  csharp: "cs",
  go: "go",
  rust: "rs",
  ruby: "rb",
  php: "php",
  swift: "swift",
  kotlin: "kt",
};

interface CodePreviewProps {
  isOpen: boolean;
  onClose: () => void;
  streamingContent: string | null;
  language: string;
  isStreaming?: boolean;
  onEditRequest?: (selectedCode: string, fullCode: string) => void;
}

export const CodePreview: React.FC<CodePreviewProps> = ({
  isOpen,
  onClose,
  streamingContent,
  language,
  isStreaming = false,
  onEditRequest,
}) => {
  const [activeTab, setActiveTab] = useState<"code" | "preview">("code");
  const [copySuccess, setCopySuccess] = useState<string>("");
  const [selectedCode, setSelectedCode] = useState<string>("");
  const [selectionActive, setSelectionActive] = useState<boolean>(false);
  const [selectionPosition, setSelectionPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const codeContainerRef = useRef<HTMLDivElement>(null);
  const currentLanguage = language?.toLowerCase();
  const isPreviewable = PREVIEWABLE_LANGUAGES.includes(currentLanguage);

  useEffect(() => {
    setCopySuccess("");
  }, [isOpen]);

  useEffect(() => {
    const handleSelectionChange = () => {
      if (!isOpen) return;

      const selection = window.getSelection();
      if (selection && selection.toString().trim() !== "") {
        let isWithinCodeContainer = false;
        if (codeContainerRef.current && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          isWithinCodeContainer =
            codeContainerRef.current.contains(range.startContainer) &&
            codeContainerRef.current.contains(range.endContainer);

          if (isWithinCodeContainer) {
            const rect = range.getBoundingClientRect();
            const containerRect =
              codeContainerRef.current.getBoundingClientRect();

            setSelectionPosition({
              x: rect.right - containerRect.left,
              y: rect.bottom - containerRect.top,
            });

            setSelectedCode(selection.toString());
            setSelectionActive(true);
          }
        }
      }
    };

    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [isOpen, selectionActive]);

  const handleCopyCode = async () => {
    if (!streamingContent) return;

    try {
      await navigator.clipboard.writeText(streamingContent);
      setCopySuccess("コピーしました");

      setTimeout(() => {
        setCopySuccess("");
      }, 2000);
    } catch (err) {
      setCopySuccess("コピーに失敗しました");
      console.error("Failed to copy code: ", err);
    }
  };

  const handleDownloadCode = () => {
    if (!streamingContent) return;

    const extension = LANGUAGE_EXTENSIONS[currentLanguage] || "txt";

    const fileName = `code.${extension}`;

    const blob = new Blob([streamingContent], { type: "text/plain" });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleEditRequest = () => {
    if (selectedCode && streamingContent && onEditRequest) {
      onEditRequest(selectedCode, streamingContent);
      setSelectionActive(false);
      setSelectionPosition(null);
      onClose();
    }
  };

  const handleCancelSelection = () => {
    setSelectedCode("");
    setSelectionActive(false);
    setSelectionPosition(null);
  };

  const sanitizeHTML = useCallback((html: string) => {
    return html
      .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
      .replace(/on\w+="[^"]*"/g, "");
  }, []);

  const updatePreview = useCallback(() => {
    if (!iframeRef.current || !streamingContent) return;

    const iframe = iframeRef.current;

    try {
      switch (currentLanguage) {
        case "html": {
          const sanitizedContent = sanitizeHTML(streamingContent);
          const html = BASE_HTML_TEMPLATE.replace(
            // eslint-disable-next-line quotes
            '<div id="root"></div>',
            `<div id="root">${sanitizedContent}</div>`,
          );
          iframe.srcdoc = html;
          break;
        }

        case "javascript":
        case "typescript": {
          try {
            if (
              streamingContent.includes("document.") ||
              streamingContent.includes("window.") ||
              streamingContent.includes("localStorage") ||
              streamingContent.includes("sessionStorage")
            ) {
              throw new Error("ブラウザAPIへのアクセスは制限されています。");
            }

            const result = new Function(streamingContent)();
            const output = `
              <div class="p-4 bg-gray-50 rounded">
                <div class="font-mono">
                  ${result !== undefined ? String(result) : "undefined"}
                </div>
              </div>
            `;

            const html = BASE_HTML_TEMPLATE.replace(
              // eslint-disable-next-line quotes
              '<div id="root"></div>',
              `<div id="root">${output}</div>`,
            );
            iframe.srcdoc = html;
          } catch (error) {
            const errorMessage = `
              <div class="p-4 bg-red-50 text-red-600 rounded">
                ${error instanceof Error ? error.message : "Unknown error"}
              </div>
            `;
            const html = BASE_HTML_TEMPLATE.replace(
              // eslint-disable-next-line quotes
              '<div id="root"></div>',
              `<div id="root">${errorMessage}</div>`,
            );
            iframe.srcdoc = html;
          }
          break;
        }

        case "jsx":
        case "react": {
          try {
            const html = REACT_PREVIEW_TEMPLATE.replace(
              "</body>",
              `
                <script type="text/babel">
                  try {
                    ${streamingContent}
                    if (typeof PreviewComponent !== 'undefined') {
                      ReactDOM.render(
                        React.createElement(PreviewComponent),
                        document.getElementById('root')
                      );
                    } else {
                      throw new Error('PreviewComponentが見つかりません。');
                    }
                  } catch (error) {
                    document.getElementById('root').innerHTML = \`
                      <div class="p-4 bg-red-50 text-red-600 rounded">
                        Error: \${error.message}
                      </div>
                    \`;
                  }
                </script>
                </body>
              `,
            );
            iframe.srcdoc = html;
          } catch (error) {
            const errorMessage = `
              <div class="p-4 bg-red-50 text-red-600 rounded">
                ${error instanceof Error ? error.message : "Unknown error"}
              </div>
            `;
            const html = BASE_HTML_TEMPLATE.replace(
              // eslint-disable-next-line quotes
              '<div id="root"></div>',
              `<div id="root">${errorMessage}</div>`,
            );
            iframe.srcdoc = html;
          }
          break;
        }
      }
    } catch (error) {
      const errorMessage = `
        <div class="p-4 bg-red-50 text-red-600 rounded">
          Preview Error: ${error instanceof Error ? error.message : "Unknown error"}
        </div>
      `;
      const html = BASE_HTML_TEMPLATE.replace(
        // eslint-disable-next-line quotes
        '<div id="root"></div>',
        `<div id="root">${errorMessage}</div>`,
      );
      iframe.srcdoc = html;
    }
  }, [streamingContent, currentLanguage, sanitizeHTML]);

  useEffect(() => {
    if (activeTab === "preview" && streamingContent) {
      updatePreview();
    }
  }, [activeTab, streamingContent, updatePreview]);

  useEffect(() => {
    if (!isOpen) {
      setActiveTab("code");
      setSelectionActive(false);
      setSelectedCode("");
      setSelectionPosition(null);
    }
  }, [isOpen]);

  return (
    <SlideModal
      isOpen={isOpen}
      onClose={onClose}
      title={`コードプレビュー (${language})`}
    >
      <div className="flex flex-col">
        <div className="border-b mb-4">
          <div className="flex">
            {isPreviewable && (
              <>
                <button
                  type="button"
                  onClick={() => setActiveTab("code")}
                  className={`px-4 py-2 ${
                    activeTab === "code"
                      ? "border-b-2 border-primary-500 text-primary-500"
                      : "text-gray-500"
                  }`}
                >
                  コード
                </button>
                <button
                  type="button"
                  onClick={() => setActiveTab("preview")}
                  className={`px-4 py-2 ${
                    activeTab === "preview"
                      ? "border-b-2 border-primary-500 text-primary-500"
                      : "text-gray-500"
                  }`}
                >
                  プレビュー
                </button>
              </>
            )}
          </div>
        </div>

        <div className="w-full">
          {activeTab === "code" || !isPreviewable ? (
            <div className="relative">
              <div
                className="code-container relative bg-gray-50 rounded-lg"
                ref={codeContainerRef}
              >
                <pre className="p-4 overflow-x-auto">
                  <code className="text-sm font-mono whitespace-pre">
                    {streamingContent}
                  </code>
                </pre>

                {selectionActive && selectionPosition && (
                  <div
                    className="flex items-center absolute bg-gradient-to-r from-white to-gray-50 rounded-full shadow-md border border-gray-100 overflow-hidden"
                    style={{
                      left: `${Math.min(selectionPosition.x, codeContainerRef.current?.clientWidth ? codeContainerRef.current.clientWidth - 90 : 0)}px`,
                      top: `${selectionPosition.y + 8}px`,
                      zIndex: 10,
                    }}
                  >
                    <button
                      type="button"
                      onClick={handleEditRequest}
                      className="flex items-center justify-center p-2 hover:bg-primary-50 text-primary-600 transition-colors"
                      aria-label="選択部分を編集"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </button>
                    <div className="w-px h-6 bg-gray-200"></div>
                    <button
                      type="button"
                      onClick={handleCancelSelection}
                      className="flex items-center justify-center p-2 hover:bg-red-50 text-gray-500 hover:text-red-500 transition-colors"
                      aria-label="選択をキャンセル"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-4 flex justify-end items-center">
                {copySuccess && (
                  <span className="text-sm text-green-500 mr-4">
                    {copySuccess}
                  </span>
                )}
                <IconButton
                  icon={{
                    icon: "mdContentCopy",
                    size: "1.25rem",
                    color: "text-blueGray-500",
                  }}
                  onClick={handleCopyCode}
                  tooltip={{
                    text: "コピーする",
                    direction: "top",
                    className: "text-xs",
                  }}
                  disabled={!streamingContent}
                />
                <div className="mx-3"></div>
                <IconButton
                  icon={{
                    icon: "bsDownload",
                    size: "1.25rem",
                    color: "text-blueGray-500",
                  }}
                  onClick={handleDownloadCode}
                  tooltip={{
                    text: "ダウンロードする",
                    direction: "top",
                    className: "text-xs",
                  }}
                  disabled={!streamingContent}
                />
                {isStreaming && (
                  <span className="inline-block animate-pulse text-primary-500 ml-4">
                    ●
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div
              className="preview-container bg-white rounded-lg border"
              style={{ height: "400px" }}
            >
              <iframe
                ref={iframeRef}
                className="w-full h-full"
                sandbox="allow-scripts"
                title="code-preview"
              />
            </div>
          )}
        </div>
      </div>
    </SlideModal>
  );
};
