import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Image from "next/image";

import {
  DotLoadingAnimation,
  InitialNameIcon,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";

import { useStorageDownloadBlob } from "@spesill/hooks";
import { useDownloadFileFromStorage } from "@spesill/hooks/storage/useDownloadFileFromStorage";
import { ChatRoomMessage } from "@spesill/models";
import { SourceType } from "@spesill/models/source";

import { MarkdownRenderer } from "../..";
import DownloadButton from "../../Button/DownloadButton";
import { DocumentSource } from "../../Document/ConvertedHTMLDetail/DocumentHeadingTitleForm/DocumentInsertTextForm/DocumentSource";
import Base64ImageViewer from "../ChatImage/ConvertImage";
import { CodePreview } from "../CodePreview/CodePreview";
import { MeshViewerDialog } from "../MeshViewer/MeshViewer";

type PropsType = {
  isMe?: boolean;
  isStreaming?: boolean;
  isImage?: boolean;
  isMesh?: boolean;
  lastName?: string;
  message: string;
  sources?: SourceType[];
  isInitial?: boolean;
  onCodeEditRequest?: (
    selectedCode: string,
    fullCode: string,
    codeIndex: number,
  ) => void;
  codeEditRequest?: {
    messageId: string;
    selectedCode: string;
    fullCode: string;
    codeIndex: number;
  } | null;
  messageId?: string;
  updateEditedCode?: (
    messageId: string,
    newCode: string,
    codeIndex: number,
  ) => void;
};

const extractStoragePath = (text: string): string => {
  const regex = /tenants\/[^\s\n]*/;
  const match = text.match(regex);
  return match ? match[0] : "";
};

export const MessageBox: React.FC<PropsType> = ({
  isMe = false,
  isInitial = false,
  isStreaming = false,
  message,
  lastName = "",
  sources,
  onCodeEditRequest,
  codeEditRequest,
  messageId,
  updateEditedCode,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedCodeIndex, setSelectedCodeIndex] = useState<number>(0);
  const [codeBlocks, setCodeBlocks] = useState<
    Array<{
      language: string;
      content: string;
      start: number;
      end: number;
    }>
  >([]);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const path = extractStoragePath(message);
  const { url } = useStorageDownloadBlob(path, false);
  const { downloadFile } = useDownloadFileFromStorage();

  useEffect(() => {
    const detectCodeBlocks = (text: string) => {
      const blocks: Array<{
        language: string;
        content: string;
        start: number;
        end: number;
      }> = [];

      let match;
      const regex = /```([\w-]+)?\n([\s\S]*?)```/g;
      let inProgressBlock = null;

      while ((match = regex.exec(text)) !== null) {
        const fullMatch = match[0];
        const language = match[1] || "plaintext";
        const content = match[2] || "";

        blocks.push({
          language,
          content: content.trim(),
          start: match.index,
          end: match.index + fullMatch.length,
        });
      }

      const lastBlockStart = text.lastIndexOf("```");
      if (
        lastBlockStart > -1 &&
        !text.slice(lastBlockStart).includes("```\n")
      ) {
        const langMatch = text.slice(lastBlockStart).match(/```([\w-]+)?\n/);
        if (langMatch) {
          const language = langMatch[1] || "plaintext";
          const contentStart = lastBlockStart + langMatch[0].length;
          const content = text.slice(contentStart);

          inProgressBlock = {
            language,
            content: content.trim(),
            start: lastBlockStart,
            end: text.length,
          };
        }
      }

      return { blocks, inProgressBlock };
    };

    const { blocks, inProgressBlock } = detectCodeBlocks(message);

    const allBlocks = inProgressBlock ? [...blocks, inProgressBlock] : blocks;

    setCodeBlocks(allBlocks);

    if (isStreaming) {
      if (allBlocks.length > 0 && !isDialogOpen) {
        setSelectedCodeIndex(allBlocks.length - 1);
        setIsDialogOpen(true);
      } else if (allBlocks.length > 0) {
        setSelectedCodeIndex(allBlocks.length - 1);
      }
    } else {
      if (blocks.length === 0) {
        setSelectedCodeIndex(0);
        setIsDialogOpen(false);
      } else if (selectedCodeIndex >= blocks.length) {
        setSelectedCodeIndex(blocks.length - 1);
      }
    }
  }, [message, isStreaming, isDialogOpen, selectedCodeIndex]);

  useEffect(() => {
    if (
      codeEditRequest &&
      messageId === codeEditRequest.messageId &&
      updateEditedCode &&
      !isEditing
    ) {
      setIsEditing(true);
    }
  }, [codeEditRequest, messageId, updateEditedCode, isEditing]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(message || "")
      .then(() => toast.success("テキストをコピーしました"))
      .catch(() => toast.error("コピーに失敗しました"));
  };

  const handleCodeEditRequest = (selectedCode: string, fullCode: string) => {
    if (onCodeEditRequest) {
      onCodeEditRequest(selectedCode, fullCode, selectedCodeIndex);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (!isStreaming) {
      setSelectedCodeIndex(0);
    }
  };

  const getMessageComponent = () => {
    if (path !== "") {
      const type = ChatRoomMessage.getFileType(path);
      if (type === "image") {
        return url ? (
          <Base64ImageViewer
            base64Data={url}
            altText={isMe ? "入力画像" : "出力画像"}
            onDownload={() => downloadFile(path)}
          />
        ) : (
          <div>画像を読み込み中...</div>
        );
      } else if (type === "glb") {
        const text = `生成したモデルが準備できました。以下のボタンからダウンロードできます。
        ダウンロード後はリンクからモデルを確認できます：https://gltf-viewer.donmccurdy.com/`;

        return (
          <div>
            <MarkdownRenderer text={text} />
            <div className="flex gap-4 mt-4">
              <DownloadButton downloadFile={() => downloadFile(path)} />
              <MeshViewerDialog
                meshPath={path}
                title="3Dモデルビューワー"
                initialWidth={700}
                initialHeight={600}
                minWidth={300}
                minHeight={200}
              />
            </div>
          </div>
        );
      }
    }

    if (codeBlocks.length > 0) {
      if (
        isEditing &&
        codeEditRequest &&
        messageId === codeEditRequest.messageId
      ) {
        return (
          <div>
            <div className="bg-yellow-50 p-2 rounded-md mb-4 text-sm">
              コード編集中... 新しいレスポンスを待っています。
            </div>
            {codeBlocks.map((block, index) => {
              const beforeText = message.slice(
                index === 0 ? 0 : codeBlocks[index - 1]?.end ?? 0,
                block.start,
              );

              return (
                <React.Fragment key={index}>
                  {beforeText && <MarkdownRenderer text={beforeText} />}
                  {index === codeEditRequest.codeIndex ? (
                    <div className="border-l-4 border-yellow-400 pl-2 my-2">
                      <div className="bg-yellow-50 text-xs p-1 rounded-t-md">
                        編集リクエスト中のコード
                      </div>
                      <pre className="mt-0 bg-gray-50 p-4 rounded-b-lg overflow-x-auto">
                        <code className="text-sm font-mono whitespace-pre">
                          {block.content}
                        </code>
                      </pre>
                    </div>
                  ) : (
                    !isStreaming && (
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedCodeIndex(index);
                          setIsDialogOpen(true);
                        }}
                        className="mt-2 mb-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg flex items-center gap-2 text-sm transition-colors"
                      >
                        <span className="font-mono text-xs bg-gray-200 px-2 py-1 rounded">
                          {block.language}
                        </span>
                        コードをプレビュー
                      </button>
                    )
                  )}
                </React.Fragment>
              );
            })}
            {(() => {
              const lastBlock = codeBlocks[codeBlocks.length - 1];
              return lastBlock ? (
                <MarkdownRenderer text={message.slice(lastBlock.end)} />
              ) : null;
            })()}
          </div>
        );
      }

      return (
        <div>
          {codeBlocks.map((block, index) => {
            const beforeText = message.slice(
              index === 0 ? 0 : codeBlocks[index - 1]?.end ?? 0,
              block.start,
            );

            return (
              <React.Fragment key={index}>
                {beforeText && <MarkdownRenderer text={beforeText} />}
                {!isStreaming && (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedCodeIndex(index);
                      setIsDialogOpen(true);
                    }}
                    className="mt-2 mb-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg flex items-center gap-2 text-sm transition-colors"
                  >
                    <span className="font-mono text-xs bg-gray-200 px-2 py-1 rounded">
                      {block.language}
                    </span>
                    コードをプレビュー
                  </button>
                )}
              </React.Fragment>
            );
          })}
          {(() => {
            const lastBlock = codeBlocks[codeBlocks.length - 1];
            return lastBlock ? (
              <MarkdownRenderer text={message.slice(lastBlock.end)} />
            ) : null;
          })()}
        </div>
      );
    }

    return <MarkdownRenderer text={message} />;
  };

  const currentCodeBlock = codeBlocks[selectedCodeIndex];

  return (
    <li
      className="flex flex-row gap-2 items-start w-full text-sm"
      style={{ position: "relative", left: "0", transform: "none" }}
    >
      {isMe ? (
        <InitialNameIcon className="shrink-0 w-10 h-10" name={lastName} />
      ) : (
        <Image
          src="/logo-circle.png"
          alt="SPESILL LOGO"
          width="40"
          height="40"
          className={`${isStreaming ? "animate-bounce" : ""}`}
        />
      )}

      <div
        className={`p-6 rounded-r-lg rounded-bl-lg w-full mt-5 whitespace-pre-wrap ${
          isMe ? "bg-white" : "bg-primary-50"
        } ${isEditing ? "border border-yellow-400" : ""}`}
        style={{ position: "relative", left: "0", transform: "none" }}
      >
        {getMessageComponent()}
        {isStreaming && message.length === 0 && <DotLoadingAnimation />}
        {isStreaming && message.length > 0 && (
          <span className="inline-block animate-pulse">●</span>
        )}
        {!isStreaming && sources && sources?.length > 0 && (
          <DocumentSource sources={sources} className="mt-4" />
        )}
        {!isMe && !isInitial && !isStreaming && (
          <div className="flex items-end justify-end">
            <IconButton
              icon={{
                icon: "mdContentCopy",
                size: "1.25rem",
                color: "text-blueGray-500",
              }}
              onClick={handleCopy}
              tooltip={{
                text: "コピーする",
                direction: "top",
                className: "text-xs",
              }}
            />
          </div>
        )}
      </div>

      {currentCodeBlock && (
        <CodePreview
          isOpen={isDialogOpen}
          onClose={handleCloseDialog}
          streamingContent={currentCodeBlock.content}
          language={currentCodeBlock.language}
          isStreaming={isStreaming}
          onEditRequest={handleCodeEditRequest}
        />
      )}
    </li>
  );
};
